import { scrollbar, flexCenter } from 'style/global.styles';
import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontWeight } from 'style/variables';

export const root = css`
  padding: calc(var(--unit) * 6) 0;
  border-bottom: 1px solid hsl(var(--color-borderDark-5));
  position: relative;

  @media ${breakpoints.large} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const rootNoBorder = css`
  ${root};
  border: none;
`;

export const logo = css`
  svg {
    width: calc(var(--unit) * 18);
  }
`;

export const navSection = css`
  position: absolute;
  display: flex;
  top: 20px;
  right: 0;

  @media ${breakpoints.large} {
    position: static;
    margin-left: ${spacing(40)};
  }
`;

export const barSection = css`
  display: flex;
  align-items: center;
  padding-top: ${spacing(46)};
  white-space: nowrap;
  justify-content: space-between;
  overflow-x: auto;

  ${scrollbar};

  @media ${breakpoints.medium} {
    justify-content: flex-start;
  }

  @media ${breakpoints.large} {
    justify-content: space-between;
    padding-top: 0;
    overflow-x: visible;
  }
`;

export const firstSectionsButton = css`
  display: flex;
`;

export const secondSectionButton = css`
  display: inline-flex;

  a {
    margin-right: calc(var(--unit) * 5);
  }
`;

export const notificationBtn = css`
  display: flex;
  align-items: center;
  padding: calc(var(--unit) * 2) calc(var(--unit) * 4);
  border: 1px solid hsl(var(--color-borderDark-5));
  border-radius: calc(var(--unit) * 4);
  margin: 0 calc(var(--unit) * 3);

  span {
    font-weight: ${fontWeight.bold};
    color: hsl(var(--color-text-11));
    font-size: var(--font-size-tiny);
    line-height: var(--line-height-tiny);
    margin-left: calc(var(--unit) * 2);
  }
`;

export const hasNotification = css`
  border: 1px solid hsl(var(--color-error-7));

  span {
    color: hsl(var(--color-error-7));
  }
`;

export const wrapper = css`
  overflow-x: auto;
`;

export const messages = css`
  padding: ${spacing(8)} ${spacing(12)};
  border: 1px solid hsl(var(--color-borderDark-5));
  border-radius: ${spacing(16)};
  height: ${spacing(32)};
  ${flexCenter};
  margin-left: ${spacing(12)};
  transition: border 0.2s;

  span {
    color: hsl(var(--color-textLight-7));
    ${font.size.tiny};
    ${font.weight.bold};
    margin-left: ${spacing(8)};
    transition: color 0.2s;
  }

  path {
    fill: hsl(var(--color-grayscale-4));
    transition: fill 0.2s;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid hsl(var(--color-borderLight-8));

    span {
      color: hsl(var(--color-borderLight-8));
    }

    path {
      fill: hsl(var(--color-borderLight-8));
    }
  }
`;

export const messagesUnread = css`
  ${messages};
  background: hsl(var(--color-error-5));
  border-color: hsl(var(--color-error-5));

  span {
    color: hsl(var(--color-white-11));
  }

  path {
    fill: hsl(var(--color-white-11));
  }
`;
